<template>
  <div class="content">
    <div class="course-banner">
			<el-carousel :interval="5000" arrow="hover" 
      indicator-position="none" height="3rem">
				<el-carousel-item 
        :data="courseBannerList" 
        v-for="item in courseBannerList" 
        :key="item.id">
					<img :src="item.image" class="image">
				</el-carousel-item>
			</el-carousel>
		</div>
    <div class="course-con">
      <div v-html="maininfo.mp_content"></div>
      <el-empty description="暂无数据" v-if="!maininfo.content"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: this.$route.query.id ? this.$route.query.id : 1,
      image:'',
      courseBannerList:[],
      courseList: [],
      maininfo: {},
    };
  },
  mounted () {
      this.getCourseList();
		  this.getBannerList();
		},
  methods: {
    //banner
    getBannerList() {					
      this.$http.get('/api/wap/index/getCourseBanner')
      .then((res) => { 
        this.courseBannerList = res.data.courseBannerList;        
      })
    },
    //课程内容
    getCourseList() {
      this.$http.get('/api/wap/index/getCourseList')
      .then((res) => {
        this.courseList = res.data.courseList;
        this.maininfo = res.data.courseList[this.type - 1];       
      });
    },
  },
};
</script>

<style lang='less' scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .course-banner {
    width: 100%;
    height: 3rem;
    img {
      display: block;
      width: 100%;
      height: 3rem;
      object-fit: cover;
      object-position: center;
    }
  }
  /deep/.course-con {
    width: 100%;
    padding: .24rem .24rem .1rem .24rem;
    background-color: #fff;
    p {
      display: block;
      font-size: .26rem;
      font-weight: 500;
      text-align: left;
      text-indent: .40rem;
      color: #666666;
      line-height: .48rem;
      margin-top: 0;
    }
    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
